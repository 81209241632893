jQuery(function($) {


	// ------------
	// Sicky Header
	// ------------

	const body = document.body;
	const scrollUp = "scroll-up";
	const scrollDown = "scroll-down";
	let lastScroll = 0;

	window.addEventListener("scroll", () => {

		const currentScroll = window.pageYOffset;

		if (currentScroll == 0) {
			body.classList.remove(scrollUp);
			return;
		}
		if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
			// down
			body.classList.remove(scrollUp);
			body.classList.add(scrollDown);
		}
		else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
			// up
			body.classList.remove(scrollDown);
			body.classList.add(scrollUp);
		}

		lastScroll = currentScroll;

	});


	// ----------
	// Slide-Menu
	// ----------

	var slideMenu = $('#slide-menu').slideMenu({
		position: 'left',
		backLinkBefore: '<svg width="24" height="24"><use xlink:href="#chevron-left"/></svg>',
		submenuLinkAfter: '<svg width="24" height="24"><use xlink:href="#chevron-right"/></svg>',
	});

	$('.slide-menu-busqueda-enlace').append('<svg class="svg-busqueda-enlace" width="24" height="24"><use xlink:href="#magnify"/></svg><svg width="24" height="24"><use xlink:href="#chevron-right"/></svg>');

	$('ul.slide-menu-busqueda input#search-input').attr('placeholder', 'Escribí tu búsqueda acá');


	// --------------
	// Slide-Búsqueda
	// --------------

	var slideBusqueda = $('#slide-busqueda').slideMenu({
		position: 'left',
	});


	// ----------
	// 

	$('.noticia img').attr('data-fancybox', 'imagenes-ilustrativas');


	// ----------------
	// Galería Fancybox
	// ----------------

	Fancybox.bind("[data-fancybox]", {
	});


	// --------
	// Count-Up
	// --------

	// Verificar y inicializar CountUp para cada elemento
	function iniciarCountUp(id, valor, opciones = {}) {
		const elemento = document.getElementById(id);
		if (elemento) {
			new countUp.CountUp(id, valor, opciones).start();
		}
	}

	// Inicializar los contadores si los elementos existen
	iniciarCountUp('countup-datos-clubes', 16, { enableScrollSpy: true });
	iniciarCountUp('countup-datos-fundacion', 1938, { useGrouping: false, enableScrollSpy: true });
	iniciarCountUp('countup-datos-categorias', 7, { enableScrollSpy: true });
	iniciarCountUp('countup-datos-localidades', 11, { enableScrollSpy: true });


	// -----------
	// Slick Slide
	// -----------

	// Portada - Primera División
	// --------------------------

	$('.primera-resultados').slick({
		prevArrow: '<div class="slick-anterior"><svg width="48" height="48"><use xlink:href="#chevron-left"/></svg></div>',
		nextArrow: '<div class="slick-siguiente"><svg width="48" height="48"><use xlink:href="#chevron-right"/></svg></div>',
		dots: true,
		slidesToShow: 1,
		infinite: false
	});

	$('.primera-posiciones').slick({
		prevArrow: '<div class="slick-anterior"><svg width="48" height="48"><use xlink:href="#chevron-left"/></svg></div>',
		nextArrow: '<div class="slick-siguiente"><svg width="48" height="48"><use xlink:href="#chevron-right"/></svg></div>',
		dots: true,
		slidesToShow: 1,
		infinite: false
	});

	$('.primera-programacion').slick({
		prevArrow: '<div class="slick-anterior"><svg width="48" height="48"><use xlink:href="#chevron-left"/></svg></div>',
		nextArrow: '<div class="slick-siguiente"><svg width="48" height="48"><use xlink:href="#chevron-right"/></svg></div>',
		dots: true,
		slidesToShow: 1,
		infinite: false
	});

	// Torneos
	// -------

	function iniciarSSlider(clase, opciones = {}) {

		const elemento = $(clase);
		if (elemento.length && !elemento.hasClass('slick-initialized')) {
			elemento.slick(opciones);
		}
		
	}

	function iniciarSSliderPorTarget(target) {

		if (target === '#resultados') {
			iniciarSSlider('.categoria-resultados', { 
				dots: true,
				infinite: false,
				prevArrow: '<div class="slick-anterior"><svg width="48" height="48"><use xlink:href="#chevron-left"/></svg></div>',
				nextArrow: '<div class="slick-siguiente"><svg width="48" height="48"><use xlink:href="#chevron-right"/></svg></div>',
			});
		}
		else if (target === '#posiciones') {
			iniciarSSlider('.categoria-posiciones', {
				dots: true,
				infinite: false,
				prevArrow: '<div class="slick-anterior"><svg width="48" height="48"><use xlink:href="#chevron-left"/></svg></div>',
				nextArrow: '<div class="slick-siguiente"><svg width="48" height="48"><use xlink:href="#chevron-right"/></svg></div>',
			});
		}

	}

	// Al cambiar de pestaña
	$('button[data-toggle="tab"]').on('shown.bs.tab', function (e) {

		const target = $(e.target).data('target');
		iniciarSSliderPorTarget(target);

	});

	// Al cargar la página
	const activeButton = $('.nav-tabs .nav-link.active');

	if (activeButton.length > 0) {

		const target = activeButton.data('target');
		iniciarSSliderPorTarget(target);

	}


	// -------
	// Masonry
	// -------

	var $grid = $('.grid-fotos').masonry({
		itemSelector: '.grid-item',
	});

	$grid.imagesLoaded().progress( function() {
		$grid.masonry('layout');
	});


	// ----------------------
	// Formulario de Contacto
	// ----------------------

	$('#contacto').validate();

	$('#contacto').on('submit', function(e) {

		// if the validator does not prevent form submit
		if (!e.isDefaultPrevented()) {

			var url = WPURLS.themedir + "/contacto.php";

			// POST values in the background the the script URL
			$.ajax({

				type: "POST",
				url: url,
				data: $(this).serialize(),
				success: function(data) {

					console.log(data);

					// data = JSON object that contact.php returns
					// we recieve the type of the message: success x danger and apply it to the 
					if (data == 'success') {
						var messageText = 'El mensaje ha sido enviado. ¡Gracias! En breve nos pondremos en contacto.';
					}
					else {
						var messageText = 'Hubo un error al enviar el mensaje. Por favor, inténtalo más tarde.';
					}

					// let's compose Bootstrap alert box HTML
					var alertBox = '<div class="alert alert-' + data + ' role-alert">' + messageText + '</div>';

					// If we have messageText
					if (messageText) {
						// inject the alert to #notificaciones div in our form
						$('#notificaciones').html(alertBox);
						// empty the form
						$('#contacto')[0].reset();
						// Esperar un momento y recargar la página
						setTimeout(function() {
							location.reload();
						}, 2000);
					}

				}

			});
			
			return false;

		}

	});


	// ------------------
	// La Liga en Números
	// ------------------

	const elementos = document.querySelectorAll('#labeccar-datos');

	if (elementos.length > 0) {

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add('en-pantalla');
				}
				else {
					entry.target.classList.remove('en-pantalla');
				}
			});

		},
		{
			root: null,
			rootMargin: '0px',
			threshold: 0.5
		});

		elementos.forEach((elemento) => observer.observe(elemento));
	}


});
